import { supabase } from "../client";
import { showToastFailure, showToastSuccess } from "./toast";
import { useUser } from '../contexts/UserContext';


export const getPass = async () => {
  try {
    const { data, error } = await supabase
      .from('pass')
      .select('*')
      .order('created_at', { ascending: false });
  

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    showToastFailure(error.message);
    return [];
  }
}

export const fetchPassDetails = async (selectedPassId) => {
    try {
      if (!selectedPassId) {
        throw new Error('No pass selected');
      }
  
      // Fetch details from 'pass_details' table
      const { data: details, error } = await supabase
        .from('pass_details')
        .select('*')
        .eq('pass_id', selectedPassId)
        .order('created_at', { ascending: false });
  
      if (error) {
        throw new Error('Error fetching pass details: ' + error.message);
      }
        return details;
    } catch (error) {
      showToastFailure('Error getting pass details')
      return [];
    }
  };

 export const getPassDetailsByIds = async (passIds) => {
    if (!Array.isArray(passIds) || passIds.some(id => typeof id !== 'string')) {
      return [];
    }
    const { data: passes, error } = await supabase
      .from('pass_details')
      .select('*')
      .in('id', passIds)
  
    if (error) {
      showToastFailure('Error getting pass details')
      return [];
    }
  
    return passes;
  };


export const updatePass = async (updatedRow) => {
  try {
    const { data, error } = await supabase
      .from('pass')
      .update({
        name: updatedRow?.name,
        description: updatedRow?.description,
        is_active: updatedRow?.is_active,
      })
      .eq('id', updatedRow.id).select()
    if (error) {
      showToastFailure(error.message || "Unable to update, please refresh screen")
      throw error;
    }
    showToastSuccess("Data Updated Successfully")
    return data;
  } catch (error) {
    showToastFailure(error.message);
    return null;
  }
};

export const useInsertPass = () => {
  const { user } = useUser();

  const insertPass = async (newPass) => {
    try {
      const { data, error } = await supabase
        .from('pass')
        .insert({
          name: newPass?.name,
          description: newPass?.description,
          is_active: true,
          user_id: user ? user?.id : ''
        })
        .select();

      if (error) {
        showToastFailure(error.message || "Unable to insert, please try again");
        throw error;
      }
      showToastSuccess("Data Inserted Successfully");
      return data;
    } catch (error) {
      showToastFailure(error.message);
      return null;
    }
  };

  return { insertPass };
};

export const updatePassDetails = async (updatedRow) => {
  try {
    const { data, error } = await supabase
      .from('pass_details')
      .update({
        title: updatedRow.title,
        description: updatedRow.description,
        amount: updatedRow.amount,
        max_hours_allowed: updatedRow.max_hours_allowed,
        is_active: updatedRow.is_active,
        no_of_tickets: updatedRow?.no_of_tickets
    
      })
      .eq('id', updatedRow?.id).select()
    if (error) {
      showToastFailure(error.message || "Unable to update, please refresh screen")
      throw error;
    }
    showToastSuccess("Data Updated Successfully")
    return data;
  } catch (error) {
    showToastFailure(error.message || "Unable to update, please refresh screen")
    showToastFailure(error.message);
    return null;
  }
};

export const useInsertPassDetails = () => {
  const { user } = useUser();

  const insertMetaDetails = async (newPass) => {
    try {
      const { data, error } = await supabase
        .from('pass_details') 
        .insert({
          title: newPass.title,
          description: newPass.description,
          amount: newPass.amount,
          max_hours_allowed: newPass.maxHoursAllowed,
          pass_id: newPass.type,
          no_of_tickets: newPass.no_of_tickets,
          is_active: true,
          user_id: user ? user.id : ''
        })
        .select();

      if (error) {
        showToastFailure(error.message || "Unable to insert, please try again");
        throw error;
      }
      showToastSuccess("Data Inserted Successfully");
      return data;
    } catch (error) {
      showToastFailure(error.message);
      return null;
    }
  };

  return { insertMetaDetails };
};
