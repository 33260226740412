import React from 'react';
import { Box, Typography, Switch } from '@mui/material';
import { styled } from '@mui/system';

const CustomSwitch = styled(Switch)(({ theme, checked }) => ({
  '& .MuiSwitch-switchBase': {
    color: checked ? 'green' : 'red',
    '&.Mui-checked': {
      color: 'green',
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: checked ? 'green' : 'red',
  },
  '& .MuiSwitch-track': {
    backgroundColor: checked ? 'lightgreen' : 'lightcoral',
  },
}));

const SwitchWithLabels = ({ checked, onChange }) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <CustomSwitch
      checked={checked}
      onChange={onChange}
      sx={{
        '& .MuiSwitch-switchBase': {
          color: checked ? 'green' : 'red',
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: checked ? 'green' : 'red',
        },
        '& .MuiSwitch-track': {
          backgroundColor: checked ? 'lightgreen' : 'lightcoral',
        },
      }}
    />
    <Typography variant="body2" sx={{ marginLeft: 1, color: checked ? 'green' : 'red' }}>
      {checked ? 'Active' : 'Inactive'}
    </Typography>
  </Box>
);

export default SwitchWithLabels;
