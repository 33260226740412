import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { usePassContext } from '../contexts/PassContext';

// Define the schema using yup
const schema = yup.object({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required')
});

const PassForm = ({ onClose }) => {
  const { insertNewPass } = usePassContext();

  const { control, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const response = await insertNewPass(data);    
    if (response?.length) {
      if (onClose) onClose();
      reset();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 16 }}>
      <Controller
        name="name"
        defaultValue=''
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Name"
            fullWidth
            margin="normal"
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        )}
      />

      <Controller
        name="description"
        defaultValue=''
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Description"
            fullWidth
            multiline
            rows={5}
            margin="normal"
            error={!!errors.description}
            helperText={errors.description?.message}
          />
        )}
      />


      <Button type="submit"
        sx={{
          backgroundColor: '#756394',
          color: '#FFFFFF',
          marginTop: '10px'
        }} variant="contained"
        fullWidth>
        Submit
      </Button>
    </form>
  );
};

export default PassForm;
