import React, { useState, useEffect, useRef } from 'react';
import { supabase } from '../client'; // import your Supabase client
import DeleteIcon from '@mui/icons-material/Delete';
import { useLoading } from '../contexts/LoadingContext';
import { showToastFailure } from '../service/toast';

const ImageGallery = () => {
  const [bannerImages, setBannerImages] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const { showLoader, hideLoader } = useLoading();
  const fileInputRef = useRef(null);

  // Fetch images from Supabase storage
  const fetchImages = async () => {
    try {
      showLoader()
      // Fetch list of files from the 'banners' folder inside the 'images' bucket
      const { data: files, error } = await supabase
        .storage
        .from('images') // The name of the Supabase bucket
        .list('banners'); // Folder path inside the bucket

      if (error) {
        return;
      }

      if (files && files?.length > 0) {

        // Fetch public URLs for all files in parallel
        const urlPromises = files.map(async (file) => {
          try {
            const { data: publicUrlData, error: urlError } = await supabase
              .storage
              .from('images')
              .getPublicUrl(`banners/${file.name}`);

            if (urlError) {
              console.error(`Error fetching public URL for ${file.name}:`, urlError.message);
              return {
                ...file,
                transformedImageUrl: null,
              };
            }

            return {
              ...file,
              transformedImageUrl: publicUrlData?.publicUrl
                ? `${publicUrlData.publicUrl}?width=200&height=200` // Adjust size as needed
                : null,
            };
          } catch (err) {
            console.error(`Error processing file ${file.name}:`, err.message);
            return {
              ...file,
              transformedImageUrl: null,
            };
          }
        });

        // Wait for all URL fetching to complete
        const filesWithUrls = await Promise.all(urlPromises);
        setBannerImages(filesWithUrls);
      } else {
      }
    } catch (err) {
      console.error("Error processing images:", err.message);
    } finally {
      hideLoader()
    }
  };


  useEffect(() => {
    fetchImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Upload image to Supabase storage
  const handleImageUpload = async (event) => {
    event.preventDefault();
    showLoader();
    if (!selectedFile) {
      showToastFailure('Please select an image to upload.');
      hideLoader(); // Hide the loading indicator
      return;
    }

    // Check if the file is an image
    const validImageTypes = ['image/jpeg', 'image/png', 'image/svg+xml']; // Add more types if needed
    if (!validImageTypes.includes(selectedFile.type)) {
      alert('Please upload a valid image file (jpg, jpeg, png, svg).');
      showToastFailure('Please upload a valid image file (jpg, jpeg, png, svg')
      hideLoader();
      return;
    }

    // Check file size (in bytes), 300KB = 300 * 1024
    const MAX_SIZE = 300 * 1024; // 300
    if (selectedFile.size > MAX_SIZE) {
      showToastFailure('Image size must be less than 300KB.');
      hideLoader();
      return;
    }

    const folderPath = 'banners'; // Path to the nested folder
    const fileName = `${folderPath}/${Date.now()}_${selectedFile.name}`;

    const { error } = await supabase
      .storage
      .from('images') // Name of your Supabase bucket
      .upload(fileName, selectedFile);
    setSelectedFile(null); // Clear the selected file
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Clear the file input field
    }
    if (error) {
      return
    } else {
      fetchImages(); // Refresh the images after upload
    }

    hideLoader();
  };

  // Handle image deletion
  const handleDeleteImage = async (imageName) => {
    showLoader()
    const { error } = await supabase
      .storage
      .from('images') // The name of the Supabase bucket
      .remove([`banners/${imageName}`]); // The file path inside the bucket
    hideLoader();
    if (error) {
    } else {
      fetchImages(); // Refresh the image list after deletion
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">Image Gallery</h1>

      {/* Image upload form */}
      <form onSubmit={handleImageUpload} className="mb-6 p-4 bg-white rounded-lg shadow-md">
        <label className="block text-gray-700 mb-2">Upload Image:</label>
        <input
          type="file"
          ref={fileInputRef}
          accept="image/jpeg, image/png, image/svg+xml"
          onChange={(e) => setSelectedFile(e.target.files[0])}
          className="block w-full mb-4 border rounded-lg p-2"
        />
        <div className="mb-4">
          <p className="text-red-500 font-bold">
            Please upload only images less than 300 KB.
          </p>
          <p>
            Need help reducing the file size? Try this
            <a
              href="https://www.simpleimageresizer.com/resize-image-to-300-kb"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline ml-1"
            >
              online image converter
            </a>
          </p>
        </div>
        <button
          type="submit"
          className="w-full px-4 py-2 bg-customPurple text-white rounded-lg shadow hover:bg-blue-700"
        >
          Upload Image
        </button>
      </form>


      {/* Display banner images in a grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {bannerImages?.map((bannerData, index) => (
          <div key={index} className="relative bg-white rounded-lg overflow-hidden shadow-lg">
            <img
              src={bannerData.transformedImageUrl}
              alt={`image_alt ${index}`}
              className="w-full h-full object-cover"
              loading="lazy"
            />
            <button
              className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full shadow hover:bg-red-700"
              onClick={() => handleDeleteImage(bannerData.name)}
            >
              <DeleteIcon />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageGallery;
