import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, Grid } from '@mui/material';
import { useDashboardData } from '../service/dashboard';
import ViewQR from '../components/viewQR';
import { useUser } from '../contexts/UserContext';
import { formatIndianCurrency } from '../utils/IndianCurrencyConveter';

const DashboardScreen = () => {
  const { getDashboardData } = useDashboardData();
  const [data, setData] = useState(null)
  const { user } = useUser();
  const isSuperAdmin = user?.role === 'super_admin';

  const fetchData = useCallback(async () => {
    const result = await getDashboardData();
    setData(result);
  }, [getDashboardData]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={3}>

        {/* QR Component */}
        <Grid item xs={12} md={12}>
          <Card>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="h6" gutterBottom sx={{ color: '#756394' }}>
                Manage QR
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <ViewQR />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Order Summary Card */}
        <Grid item xs={12} md={6} lg={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ color: '#756394' }}>
                Order Summary
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={4} lg={4}>
                  <Typography>Today's Order Count: {formatIndianCurrency(data?.todays_order_count)}</Typography>
                  {isSuperAdmin && <>
                    <Typography>Yesterday's Order Count: {data?.yesterdays_order_count}</Typography>
                    <Typography>Last 7 Days Count: {data?.last_7_days_order_count}</Typography>
                    <Typography>This Month's Order Count: {formatIndianCurrency(data?.this_month_order_count)}</Typography>
                    <Typography>This Year's Order Count: {formatIndianCurrency(data?.this_year_order_count)}</Typography>
                    <Typography>Total Orders: {formatIndianCurrency(data?.total_orders)}</Typography>

                  </>}  </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Payment Summary Card */}
        <Grid item xs={12} md={6} lg={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ color: '#756394' }}>
                Payment Summary
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>Today's Amount (with GST): {formatIndianCurrency(data?.todays_amount_with_gst?.toFixed(2)) || 0}</Typography>
                  {isSuperAdmin && <>
                    <Typography>Yesterday's Amount (with GST): {formatIndianCurrency(data?.yesterdays_amount_with_gst?.toFixed(2)) || 0}</Typography>
                    <Typography>Last 7 Days Amount (with GST): {formatIndianCurrency(data?.last_7_days_amount_with_gst?.toFixed(2)) || 0}</Typography>
                    <Typography>This Month's Amount (with GST): {formatIndianCurrency(data?.this_month_amount_with_gst?.toFixed(2)) || 0}</Typography>
                    <Typography>This Year's Amount (with GST): {formatIndianCurrency(data?.this_year_amount_with_gst?.toFixed(2)) || 0}</Typography>
                    <Typography>Successful Payments: {formatIndianCurrency(data?.successful_payments)}</Typography>
                    <Typography>Failed Payments: {formatIndianCurrency(data?.failed_payments)}</Typography>

                  </>} </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* GST Card */}
        <Grid item xs={12} md={6} lg={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ color: '#756394' }}>
                GST Details
              </Typography>
              <Typography>GST: {data?.total_gst?.toFixed(2)}%</Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Pass Details Card */}
        <Grid item xs={12} md={6} lg={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ color: '#756394' }}>
                Pass Details
              </Typography>
              <Typography>Total Pass Details: {data?.total_pass_details}</Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Users Card */}
        <Grid item xs={12} md={6} lg={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ color: '#756394' }}>
                Users Summary
              </Typography>
              <Typography>Total Users: {data?.total_users}</Typography>
            </CardContent>
          </Card>
        </Grid>

      </Grid>
    </Box>
  );
};

export default DashboardScreen;
