import { useLoading } from '../contexts/LoadingContext'; 
import { supabase } from '../client';

export const useDashboardData = () => {
  const { showLoader, hideLoader } = useLoading();

  const getDashboardData = async () => {
    showLoader();
    try {
      const { data, error } = await supabase.rpc('get_dashboard_data');
      if (error) {
        throw error;
      }
      return data;
    } catch (error) {
      throw error;
    } finally {
      hideLoader();
    }
  };

  return { getDashboardData };
};
