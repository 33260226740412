import React from 'react'
import PassDetailsTable from '../tables/PassDetailsTable'
import SideNavDrawer from '../components/SideNavDrawer'
import PassDetailsForm from '../forms/PassDetailForm'

function PassDetails() {
  return (
    <>
      <SideNavDrawer component={PassDetailsForm} />
      <div style={{ marginTop: '20px' }}>
        <PassDetailsTable />
      </div>
    </>
  )
}

export default PassDetails