import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { usePassContext } from '../contexts/PassContext';

// Define the schema using yup
const schema = yup.object({
  title: yup.string().required('Title is required'),
  description: yup.string().required('Description is required'),
  amount: yup.number()
    .typeError('Amount must be a number')
    .required('Amount is required')
    .positive('Amount must be positive'),
  maxHoursAllowed: yup.number()
    .typeError('Maximum Hours Allowed must be a number')
    .required('Maximum Hours Allowed is required')
    .positive('Value must be positive'),
    no_of_tickets: yup.number()
    .typeError('Maximum Ticket Allowed must be a number')
    .required('Maximum Ticket Allowed is required')
    .positive('Value must be positive'),
  type: yup.string().required('Type is required'),
});

const PassDetailsForm = ({ onClose }) => {

  const { activePasses, insertNewSubPassData } = usePassContext();

  const { control, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });


  const onSubmit = async (data) => {
    const response = await insertNewSubPassData(data)
    if (response) {
      if (onClose) onClose();
      reset();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 16 }}>
      <Controller
        name="title"
        defaultValue=""
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Title"
            fullWidth
            margin="normal"
            error={!!errors.title}
            helperText={errors.title?.message}
          />
        )}
      />
      <Controller
        name="description"
        defaultValue=""
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Description"
            fullWidth
            multiline
            rows={5}
            margin="normal"
            error={!!errors.description}
            helperText={errors.description?.message}
          />
        )}
      />
      <Controller
        name="amount"
        defaultValue=""
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Price"
            fullWidth
            margin="normal"
            type="number"
            error={!!errors.amount}
            helperText={errors.amount?.message}
          />
        )}
      />

      <Controller
        defaultValue=""
        name="no_of_tickets"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Available Tickets"
            fullWidth
            margin="normal"
            type="number"
            error={!!errors.maxTicket}
            helperText={errors.maxTicket?.message}
          />
        )}
      />

      <Controller
        defaultValue=""
        name="maxHoursAllowed"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Maximum Hours"
            fullWidth
            margin="normal"
            type="number"
            error={!!errors.maxHoursAllowed}
            helperText={errors.maxHoursAllowed?.message}
          />
        )}
      />
      <Controller
        name="type"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            select
            label="Type of Pass"
            fullWidth
            margin="normal"
            value={field.value || ""}
            error={!!errors.type}
            helperText={errors.type?.message}
          >
            {activePasses?.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type?.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <Button
        type="submit"
        variant="contained"
        sx={{
          backgroundColor: '#756394',
          color: '#FFFFFF',
          marginTop: '10px'
        }}
        fullWidth
      >
        Submit
      </Button>
    </form>
  );
};

export default PassDetailsForm;
