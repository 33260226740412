import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
  getPass,
  useInsertPass, 
  useInsertPassDetails,
  updatePass,
  fetchPassDetails,
} from '../service/passService';
import { format } from 'date-fns';
import { useLoading } from './LoadingContext';
import { showToastFailure } from '../service/toast';

const PassContext = createContext();

export const PassProvider = ({ children }) => {
  const [passes, setPasses] = useState([]);
  const [subPassDetails, setSubPassDetails] = useState([]);
  const [selectedPassId, setSelectedPassId] = useState(null);
  const [activePasses, setActivePasses] = useState([]);
  const { insertPass } = useInsertPass();
  const { insertMetaDetails } = useInsertPassDetails();
  const { showLoader, hideLoader } = useLoading();

  useEffect(() => {
    const getPasses = async () => {
      const response =  await getPass();
      setPasses(response)
      updateActivePasses(response); 
    };
    getPasses();
  }, []);

  useEffect(() => {
    const getPassDetails = async () => {
      const details = await fetchPassDetails(selectedPassId);
      const formattedData = details?.map(item => ({
        id: item?.id,
        title: item?.title,
        description: item?.description,
        amount: item?.amount,
        max_hours_allowed: item.max_hours_allowed,
        is_active: item?.is_active,
        no_of_tickets: item?.no_of_tickets,
        created_at: format(new Date(item.created_at), 'dd/MM/yyyy HH:mm a'),
      }));

      setSubPassDetails(formattedData);
    };

    if (selectedPassId) {
      getPassDetails();
    }
  }, [selectedPassId]);

  const updateActivePasses = (updatedPasses) => {
    setActivePasses(updatedPasses.filter(pass => pass.is_active));
  };

  const insertNewPass = async (newPass) => {
  try {
    showLoader();
    const response = await insertPass(newPass);
    if (response?.length) {
      // Update the passes state with the newly inserted pass
      const updatedPass = response[0];
      const formattedData = {...updatedPass,  created_at: format(new Date(updatedPass.created_at), 'dd/MM/yyyy HH:mm a'), }
    
        setPasses(prevData => {
          const updatedData = [...prevData, formattedData];
          // Pass the updated data to updateActivePasses
          updateActivePasses(updatedData);
          return updatedData;
        });
    
      return response;
    } 
  } catch (error) {
    return null;
  } finally {
    hideLoader();
  }
};


  const insertNewSubPassData = async (newPass) => {
    showLoader()
    try {
      const response = await insertMetaDetails(newPass);
      if (response?.length) {
        if(selectedPassId === response[0]?.pass_id){
        setSubPassDetails(prevData => [...prevData, response[0]]);
        }
        return response
      }
    } catch (error) {
      showToastFailure('Error inserting pass!')
    } finally {
      hideLoader()
    }
  };


  const updateNewPass = async (updateData) => {
    try {
      const response = await updatePass(updateData);
     if (response?.length) {
        const updatedPass = response[0];
        setPasses(prevRows => {
          const updatedRows = prevRows.map(row => 
            row?.id === updatedPass.id ? updatedPass : row
          );
          updateActivePasses(updatedRows);
          return updatedRows;
        });
  
        return response;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };
  

  return (
    <PassContext.Provider value={{
      passes,
      subPassDetails, 
      setSubPassDetails,
      selectedPassId,
      setSelectedPassId,
      insertNewPass,
      updateNewPass,
      insertNewSubPassData,
      activePasses
    }}>
      {children}
    </PassContext.Provider>
  );
};

export const usePassContext = () => useContext(PassContext);