import { supabase } from "../client"
import { useUser } from "../contexts/UserContext";
import { getFormattedDate } from "../utils/formattedDateGMT";
import { showToastFailure, showToastSuccess } from "./toast";

export const getSessions = async (startDate, endDate) => {
    try {
        // Call the stored procedure
        const { data, error } = await supabase.rpc('get_session_details', {
          startdate: startDate,
          enddate: endDate
        });

        if (error) {
            throw error;
        }
        return data
    } catch (error) {
        showToastFailure('Error fetching dashboard data')
        throw error;
    }
};

export const useUpdateSessionsStatus = () => {
  const { user } = useUser();

  const updateSessionsStatus = async (sessionId, newStatus) => {
    try {
      const { data, error } = await supabase
        .from('sessions')
        .update({
          game_status: newStatus,
          updated_at: getFormattedDate(new Date()),
          updated_by: user ? user.id : null
        })
        .eq('id', sessionId)
        .select();

      if (error) {
        showToastFailure(error.message || "Unable to update, please refresh the screen");
        throw error;
      }

      showToastSuccess("Data Updated Successfully");
      return data;
    } catch (error) {
      showToastFailure(error.message || "Unable to update, try again later");
      return null;
    }
  };

  return { updateSessionsStatus };
};

