// SelectComponent.js
import React from 'react';
import { Select, MenuItem, FormControl } from '@mui/material';

const SelectComponent = ({ value, options, onChange, placeholder = "Select Status", style }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <FormControl style={style} size="small">
      <Select
        value={value}
        onChange={handleChange}
        displayEmpty
        autoWidth
        inputProps={{ 'aria-label': placeholder }}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectComponent;
