import { useLoading } from '../contexts/LoadingContext'; 
import { supabase } from '../client';
import { showToastFailure } from './toast';

export const useOrdersData = () => {
  const { showLoader, hideLoader } = useLoading();


  const getOrders = async (startDate, endDate) => {
    showLoader();
    try {
      // Call the stored procedure
      const { data, error } = await supabase
      .rpc('get_order_details_by_date_range', {
        startdate: startDate,
        enddate: endDate
      });
      if (error) {
        throw error;
      }
      return data;
    } catch (error) {
      showToastFailure(error.message);
      return [];
    } finally {
      hideLoader();
    }
  };

  return { getOrders };
};
