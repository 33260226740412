import React from 'react'
import PassTable from '../tables/PassTable'
import SideNavDrawer from '../components/SideNavDrawer'
import PassForm from '../forms/PassForm'

function Pass() {
  return (
    <>
      <SideNavDrawer component={PassForm} />
      <div style={{ marginTop: '20px' }}>
        <PassTable />
      </div>
    </>
  )
}

export default Pass