import React from 'react'
import LoginHeader from '../components/LoginHeader'
import Login from '../components/Login'

const LoginPage = () => {
  return (
    <>
      <div className="min-h-full h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-100">
        <div className="max-w-md w-full space-y-8">
          <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md mx-auto">
            <LoginHeader />
            <Login />
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginPage
