import { supabase } from "../client";
import { showToastFailure } from "./toast";

export const getAllUsers = async () => {
  const { data, error } = await supabase.from("users")
    .select('*');

  if (error) {
    return null;
  }

  return data;
}

export const getAllCustomers = async () => {
  const { data, error } = await supabase
    .from('users')
    .select('*')
    .eq('role', 'user')
    .order('created_at', { ascending: false });
  
  if (error) {
    showToastFailure('Error getting customers')
    return [];
  }

  return data;
};

export const getUserById = async (userId) => {

  // Fetch the user by userId
  const { data, error } = await supabase
    .from('users')
    .select('*')
    .eq('user_id', userId.toString())
    .single(); 

  if (error) {
    showToastFailure('Error getting customer details')
    return null;
  }

  return data;
}

export const getUsersByIds = async (userIds) => {
  if (!Array.isArray(userIds) || userIds.some(id => typeof id !== 'string')) {
    return [];
  }

  const { data: users, error } = await supabase
    .from('users')
    .select('*')
    .in('user_id', userIds);

  if (error) {
    showToastFailure('Error getting customer details')
    return [];
  }

  return users;
};



