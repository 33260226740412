import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import SwitchWithLabels from '../components/SwitchWithLables'; 
import { usePassContext } from '../contexts/PassContext';
import { showToastFailure } from '../service/toast';

const PassTable = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const { passes, updateNewPass } = usePassContext(); 

  useEffect(() => {
    if (passes?.length > 0) {
      const formattedData = passes?.map(item => ({
        id: item?.id, 
        name: item?.name,
        is_active: item?.is_active,
        created_at: format(new Date(item.created_at), 'dd/MM/yyyy HH:mm a'),
        description: item?.description,
      }));
      setRows(formattedData);
      setLoading(false);
    }
    setLoading(false);
  }, [passes]);

  const handleRowEdit = async (updatedRow) => {
    // Find the original row based on the ID
    const originalRow = rows.find(row => row.id === updatedRow.id);
  
    // Check if the name field is missing or empty
    if (!updatedRow.name || updatedRow.name.trim() === '') {
      showToastFailure('Name is a mandatory field and cannot be empty.');
      return originalRow; // Return the original row to prevent updates
    }
  
    // Check if data has changed
    const hasChanged = Object.keys(updatedRow).some(
      key => updatedRow[key] !== originalRow[key]
    );
  
    if (!hasChanged) {
      return originalRow; // No changes, return the original row
    }
  
    // Proceed with the API call since there are changes
    const response = await updateNewPass(updatedRow);
  
    if (response?.length) {
      // Update the state with the new row data if the API call was successful
      setRows(rows.map(row => (row.id === updatedRow.id ? updatedRow : row)));
      return updatedRow;
    } else {
      showToastFailure('Failed to update the row.');
      return originalRow; // If API call fails, return the original row
    }
  };
  

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1, editable: true },
    {
      field: 'description',
      headerName: 'Description',
      flex: 2,
      editable: true,
      renderCell: (params) => (
        <Tooltip title={params.value || ''} arrow>
          <span>{params.value}</span> {/* Display description value */}
        </Tooltip>
      ),
    },
    { 
      field: 'is_active', 
      headerName: 'Status', 
      flex: 1,
      editable: true,
      renderCell: (params) => {
        const isActive = params.value
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <SwitchWithLabels
              checked={isActive}
              onChange={(e) => {
                const newStatus = e.target.checked 
                const updatedRow = {
                  ...params.row,
                  is_active: newStatus
                };
                handleRowEdit(updatedRow);
              }}
            />
          </Box>
        );
      }
    },
    { field: 'created_at', headerName: 'Pass Created At', flex: 1 },
   ];

  return (
    <div style={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        loading={loading}
        processRowUpdate={handleRowEdit}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </div>
  );
};

export default PassTable;
