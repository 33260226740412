import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import AppRouter from './routes/Sidenav';
import AppProviders from './contexts';
import React from 'react';
import { supabase } from './client';
const App = () => {

  const user = localStorage.getItem('user');
  React.useEffect(() => {
    const fetchSession = async () => {
        await supabase.auth?.getSession();
    };
    if(user) {
      fetchSession();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
      <AppProviders>
        <ToastContainer />
        <AppRouter/>
      </AppProviders>
  );
}

export default App;
