export default function LoginHeader(){
    return(
        <div className="mb-10">
            <div className="flex justify-center">
                <img 
                    alt=""
                    className="h-28 w-28"
                    src="app_logo_white.webp"/>
            </div>
            <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
                Login
            </h2>
        </div>
    )
}