import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import Sidenav from './AppLayout';
import LoginPage from '../pages/Login';
import PrivateRoute from './PrivateRoute';

const AppRouter = () => {
  const { user } = useUser();
  const isLoggedIn = !!user;

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={isLoggedIn ? <Navigate to="/app/dashboard" /> : <LoginPage />}
        />
        <Route
          path="/app/*"
          element={
            <PrivateRoute>
              <Sidenav />
            </PrivateRoute>
          }
        />
        <Route
          path="/"
          element={<Navigate to={isLoggedIn ? "/app/dashboard" : "/login"} />}
        />
        <Route path="*"  element={<Navigate to={isLoggedIn ? "/app/dashboard" : "/login"} />}/>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
