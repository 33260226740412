import React, { useState } from 'react';
import { loginFields } from '../formFields/authFormField';
import Input from './Input';
import { supabase } from '../client';
import { showToastFailure } from '../service/toast';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { useLoading } from '../contexts/LoadingContext';

const fields = loginFields;
let fieldsState = {};
fields.forEach(field => fieldsState[field.id] = '');

const Login = () => {
    const [loginState, setLoginState] = useState(fieldsState);
    const navigate = useNavigate();
    const { updateUser } = useUser();
    const { showLoader, hideLoader } = useLoading();

    const handleChange = (e) => {
        setLoginState({ ...loginState, [e.target.id]: e.target.value });
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        showLoader()
        const { data, error } = await supabase.auth.signInWithPassword({
            email: loginState['email-address'],
            password: loginState.password
        });

        if (error) {
            hideLoader();
            showToastFailure(error.message);
        } else {
            // Extract user ID from the data object
            const userId = data.user.id;

            // Query the users table to check if the user is a super admin
            const { data: userData, error: userError } = await supabase
                .from('users')
                .select('*')
                .eq('user_id', userId)
                .single();
                
            if (userError) {
                showToastFailure(userError.message);
            } else {
                if (!userData.is_active) {
                    showToastFailure('Not authorized to login');
                } else if ((userData.role === 'super_admin' || userData.role === 'admin') && userData.is_active) {
                    updateUser({
                        id: userId,
                        email: loginState['email-address'],
                        token: data.session.access_token,
                        fullName: userData.first_name + '' + userData.last_name,
                        phone: userData.phone,
                        is_active: userData.is_active,
                        role: userData.role
                    })
                    navigate(`/app/dashboard`);
                } else {
                    showToastFailure('Not authorized to login');
                }
            }

            hideLoader();
        }
    };

    return (
        <form className="mt-8 space-y-6" onSubmit={handleLogin}>
            <div className="-space-y-px">
                {
                    fields.map(field => (
                        <Input
                            key={field.id}
                            handleChange={handleChange}
                            value={loginState[field.id]}
                            labelText={field.labelText}
                            labelFor={field.labelFor}
                            id={field.id}
                            name={field.name}
                            type={field.type}
                            isRequired={field.isRequired}
                            placeholder={field.placeholder}
                        />
                    ))
                }
            </div>

            <div className='btn mt-10'>
                <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-customPurple hover:bg-customPurple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-customPurple mt-10"
                >
                    Submit
                </button>
            </div>
        </form>
    );
}

export default Login;
