export const formatIndianCurrency = (value) => {
    if (value === undefined || value === null) return '0';
    const parts = value.toString().split('.');
    let integerPart = parts[0];
    const decimalPart = parts[1] ? `.${parts[1]}` : '';
  
    // Indian number format
    let lastThree = integerPart.slice(-3);
    const otherNumbers = integerPart.slice(0, -3);
    if (otherNumbers !== '') {
      lastThree = ',' + lastThree;
    }
    integerPart = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;
  
    return integerPart + decimalPart;
  };