import React from 'react';
import Select from 'react-select';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Tooltip } from '@mui/material';
import SwitchWithLabels from '../components/SwitchWithLables';
import { usePassContext } from '../contexts/PassContext';
import {  updatePassDetails } from '../service/passService';
import { format } from 'date-fns';
import { showToastFailure } from '../service/toast';

const PassDetailsTable = () => {
  const { setSelectedPassId, subPassDetails , setSubPassDetails, activePasses} = usePassContext(); 

  const setPassId = (pass) => {
    setSelectedPassId(pass.value);
  }

  const handleRowEdit = async (updatedRow) => {
    // Find the original row based on the ID
    const originalRow = subPassDetails.find((row) => row.id === updatedRow.id);
  
    // Check if mandatory fields are missing or empty
    if (
      !updatedRow.title || updatedRow.title.trim() === '' ||
      !updatedRow.amount || updatedRow.amount === '' ||
      !updatedRow.max_hours_allowed || updatedRow.max_hours_allowed === '' ||
      !updatedRow.no_of_tickets || updatedRow.no_of_tickets === ''
    ) {
      showToastFailure('Title, Amount, Max Hours Allowed, and No of Tickets are mandatory fields and cannot be empty.');
      return originalRow; // Return the original row to prevent updates
    }
  
    // Check if data has changed
    const hasChanged = Object.keys(updatedRow).some(
      (key) => updatedRow[key] !== originalRow[key]
    );
  
    if (!hasChanged) {
      return originalRow; // No changes, return the original row
    }
  
    try {
      // Proceed with the API call since there are changes
      const response = await updatePassDetails(updatedRow);
  
      if (response.length) {
        const updatedRowData = {
          id: response[0]?.id,
          title: response[0]?.title,
          description: response[0]?.description,
          amount: response[0]?.amount,
          max_hours_allowed: response[0]?.max_hours_allowed,
          no_of_tickets: response[0]?.no_of_tickets,
          is_active: response[0]?.is_active,
          created_at: format(new Date(response[0]?.created_at), 'dd/MM/yyyy HH:mm a'),
        };
  
        // Update the state with the new row data if the API call was successful
        setSubPassDetails((prevRows) =>
          prevRows.map((row) => (row.id === updatedRowData.id ? updatedRowData : row))
        );
  
        return updatedRowData;
      } else {
        showToastFailure('Failed to update the row.');
        return originalRow; // If API call fails, return the original row
      }
    } catch (error) {
      showToastFailure('An error occurred while updating the row.');
      return originalRow; // Return the original row in case of error
    }
  };
  
  

  const columns = [
    { field: 'title', headerName: 'Title', flex: 1 , editable: true},
    {
      field: 'description',
      headerName: 'Description',
      flex: 2,
      editable: true,
      renderCell: (params) => (
        <Tooltip title={params.value || ''} arrow>
          <span>{params.value}</span> {/* Display description value */}
        </Tooltip>
      ),
    },
    { field: 'amount', headerName: 'Price', flex: 1, type: 'number',  editable: true  },
    { field: 'no_of_tickets', headerName: 'Available Tickets', flex: 1, type: 'number', editable: true  },
    { field: 'max_hours_allowed', headerName: 'Maximum Hours', flex: 1, type: 'number',  editable: true  },
    { field: 'created_at', headerName: 'Date Created', flex: 1 },
    { 
      field: 'is_active', 
      headerName: 'Status', 
      flex: 1,
      editable: false,
      renderCell: (params) => {
        const isActive = params.value
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <SwitchWithLabels
              checked={isActive}
              onChange={(e) => {
                const newStatus = e.target.checked
                const updatedRow = {
                  ...params.row,
                  is_active: newStatus
                };
                handleRowEdit(updatedRow);
              }}
            />
            
          </Box>
        );
      }
    },
  ];

  return (
    <Box sx={{ padding: 2 }}>
    <Box sx={{ marginBottom: 2 }}>
        <Select
           options={activePasses?.map(option => ({
            value: option?.id,  
            label: option?.name 
          }))}
          onChange={(option) => setPassId(option)}
          placeholder="Select master pass"
        />
      </Box>
      
        <div style={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={subPassDetails}
            columns={columns}
            rowHeight={38}
            pageSize={10}
            processRowUpdate={handleRowEdit}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </div>
      
    </Box>
  );
};

export default PassDetailsTable;
