
// Define the enum
const qrTypes =  {
    'general': "General QR",
    'new_registration': "Register New User",
  }
  
// Utility function to get a description of the QR code type
export const getQRCodeDescription = (type) => {
  return qrTypes[type] || "Unknown QR code type.";
};

// Utility function to get all QR code types
export const getAllQRCodeTypes = () => {
  return Object.keys(qrTypes).map(key => ({ key, description: qrTypes[key] }));
};
  