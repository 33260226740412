// SideNavDrawer.js
import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';

// Modify the component to accept a `component` prop
const SideNavDrawer = ({ component: Component }) => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <Button onClick={toggleDrawer} variant="contained"  sx={{ backgroundColor: '#756394', color: '#FFFFFF' }}  color="primary" >
        + Add
      </Button>
      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer}
        sx={{ width: '600px' }}
      >
        <div style={{ width: '600px', marginTop: '50px', padding: '16px' }}>
          {Component && <Component onClose={toggleDrawer} />}
        </div>
      </Drawer>
    </>
  );
};

export default SideNavDrawer;
