import React, { createContext, useState,  useContext, useCallback } from 'react';

// Create a Context for user
const UserContext = createContext();

// Custom hook to use the UserContext
export const useUser = () => useContext(UserContext);

// UserProvider component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    // Initialize state from localStorage
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  // Update user in state and localStorage
  const updateUser = useCallback((userData) => {
    localStorage.setItem('user', JSON.stringify(userData));
    setUser(userData);
  }, []);

  // Clear user data
  const clearUser = useCallback(() => {
    localStorage.removeItem('user');
    setUser(null);
  }, []);


  const value = { user, updateUser, clearUser };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
