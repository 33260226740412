import { supabase } from "../client"
import { showToastFailure, showToastSuccess } from "./toast"

export const qetQRCodes = async () => {
    try {
      const { data, error } = await supabase
        .from('qr_management')
        .select('*')
      
      if (error) {
        throw error
      }
  
      return data
    } catch (error) {
      showToastFailure(error.message)
      return []
    }
  }

  export const getEventQRs = async () => {
    try {
      const currentTime = new Date().toISOString()
      const { data, error } = await supabase
        .from('events')
        .select('*')
        .gt('event_end_time', currentTime)
  
      if (error) {
        throw error
      }
  
      return data
    } catch (error) {
      showToastFailure(error.message)
      return []
    }
  }

  export const updateEventStatus = async (eventId, status) => {
    try {
      const { data, error } = await supabase
        .from('events')
        .update({ is_active: status })
        .eq('id', eventId) 
        .select()
  
      if (error) {
        throw error
      }
  
      showToastSuccess(`QR Code status updated to ${status ? 'active' : 'inactive'}`);
      return data
    } catch (error) {
      showToastFailure(error.message)
      return null
    }
  }

  export const toggleQRCodeStatus = async (qrId) => {
    try {
      // Fetch the current status of the QR code
      const { data: currentData, error: fetchError } = await supabase
        .from('qr_management')
        .select('is_active')
        .eq('id', qrId)
        .single();
  
      if (fetchError) {
        throw fetchError;
      }
  
      // Toggle the is_active value
      const newStatus = !currentData.is_active;
  
      // Update the is_active field with the new status
      const { data, error } = await supabase
        .from('qr_management')
        .update({ is_active: newStatus })
        .eq('id', qrId)
        .select();
  
      if (error) {
        throw error;
      }
  
      showToastSuccess(`QR Code status updated to ${newStatus ? 'active' : 'inactive'}`);
      return data;
    } catch (error) {
      showToastFailure(error.message || "Unable to update QR Code status, please try again");
      return null;
    }
  };
  