import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { getAllCustomers } from '../service/userService';

const CustomersTable = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getAllCustomers();
      const formattedData = data?.map(item => ({
        id: item.id,
        first_name: item.first_name,
        last_name: item.last_name,
        email: item.email,
        phone: item.phone,
        dob: item.dob && format(new Date(item.dob), 'dd/MM/yyyy'),
        created_at: format(new Date(item.created_at), 'dd/MM/yyyy HH:mm a'), 
      }));
      setRows(formattedData);
      setLoading(false);
    };

    fetchData();
  }, []);

  const columns = [
    { field: 'first_name', headerName: 'First Name', flex: 1 },
    { field: 'last_name', headerName: 'Last Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'phone', headerName: 'Phone', flex: 1 },
    { field: 'dob', headerName: 'Date of Birth', flex: 1 },
    { field: 'created_at', headerName: 'Registered Date', flex: 1 },
  ];

  return (

    <div style={{ height: 600, marginTop: 10, width: '100%' }}>
    <DataGrid
      slots={{ toolbar: GridToolbar }}
      slotProps={{ toolbar: { printOptions: { disableToolbarButton: true }, showQuickFilter: true } }}
      rows={rows}
      columns={columns}
      pageSize={20}
      loading={loading}
      localeText={{ noRowsLabel: "No Customers" }}
    />
  </div>
  );
};

export default CustomersTable;
